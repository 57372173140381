<template>
  <div>
  <alarm />
  </div>
</template>

<script>
import Alarm from "@/views/custom_app/components/alarm/Alarm.vue";
export default {
  components: {
    Alarm,
  },
  data() {
    return {
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
</style>
