<template>
  <div>
    <hr>
    <b-card-body
        class="card-transaction"
    >
<!--      <div-->
<!--          v-for="transaction in transactionData"-->
<!--          :key="transaction.mode"-->
<!--          class="transaction-item"-->
<!--      >-->
<!--        <b-media no-body>-->
<!--          <b-media-aside>-->
<!--            <b-avatar-->
<!--                rounded-->
<!--                size="42"-->
<!--                :variant="transaction.avatarVariant"-->
<!--            >-->
<!--              <feather-icon-->
<!--                  size="18"-->
<!--                  :icon="transaction.avatar"-->
<!--              />-->
<!--            </b-avatar>-->
<!--          </b-media-aside>-->
<!--          <b-media-body>-->
<!--            <h6 class="transaction-title">-->
<!--              {{ transaction.mode }}-->
<!--            </h6>-->
<!--            <small>{{ transaction.types }}</small>-->
<!--          </b-media-body>-->
<!--        </b-media>-->
<!--        <div-->
<!--            class="font-weight-bolder"-->
<!--            :class="transaction.deduction ? 'text-danger':'text-success'"-->
<!--        >-->
<!--          {{ transaction.payment }}-->
<!--        </div>-->
<!--      </div>-->
    </b-card-body>
  </div>
</template>

<script>

import {BAvatar, BBadge, BCard, BCardBody, BCol, BImg, BMedia, BMediaAside, BMediaBody, BRow} from "bootstrap-vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  components: {
    BCardBody,
    BMediaAside, BMediaBody, BAvatar, BMedia,
    BImg, Locale,
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      data: [],
      transactionData: [
        {
          mode: 'Wallet',
          types: 'Starbucks',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          payment: '-$74',
          deduction: true,
        },
        {
          mode: 'Bank Transfer',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '+$480',
          deduction: false,
        },
        {
          mode: 'Mastercard',
          types: 'Ordered Food',
          avatar: 'CreditCardIcon',
          avatarVariant: 'light-warning',
          payment: '-$23',
          deduction: true,
        },
        {
          mode: 'Transfer',
          types: 'Refund',
          avatar: 'TrendingUpIcon',
          avatarVariant: 'light-info',
          payment: '+$98',
          deduction: false,
        },
      ],

    }
  },
  created() {
  },
}
</script>

<style lang="scss">
</style>
